import React, { useState } from "react";
import Tabs from "../../common_components/Tabs";
import Filters from "./Filterbtn";
import Filterbtn2 from "./Filterbtn2";
import Table from "./Table";
import TableTransfer from "./TableTransfer";
import Pagination from "./Pagination";
import { FaLock } from "react-icons/fa";
import { useRollId } from "../../common_components/RollIdContext"; // Import the hook

const MainContent = () => {
  const [isTransferMode, setIsTransferMode] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("PKR");
  const [amount, setAmount] = useState(0);
  const handleTransferClick = () => {
    setIsTransferMode((prevMode) => !prevMode); // Toggle transfer mode
  };
  const handleCurrencySelect = (currency) => {
    setSelectedCurrency(currency);
  };
  const { rollId } = useRollId(); // Get rollId directly from context
  return (
    <div className="container-fluid main-content-table bgColor d-flex flex-column overflow-hidden mt-2">
      <hr />
      <div className="d-flex justify-content-start ms-5 d-none">
        <button
          className={`btn transferbtn ${isTransferMode ? "active" : ""}`}
          onClick={handleTransferClick}
          disabled
        >
          {isTransferMode ? "Transfers" : "Transfers"}
        </button>
      </div>
      <div className="flex-grow-1 d-flex flex-column me-3">
        <div className="d-flex justify-content-md-between items-center  align-items-center ">
          <div className="d-flex pt-3 mt-3">
            {isTransferMode && <Filterbtn2 />}
            <Filters
              selectedCurrency={selectedCurrency}
              onCurrencySelect={handleCurrencySelect}
              className="my-2 fw-bold"
            />
          </div>
          <div className="d-flex align-items-center mt-3 mt-md-0 px-2">
            <div className="balance-box p-2 border-2">
              <div>
                <div className="balance-txt">Balance</div>
                <div className="amount-txt fw-bold">
                  {selectedCurrency === "PKR"
                    ? `PKR ${amount.toLocaleString()}`
                    : `USD ${amount.toLocaleString()}`}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="table-wrapper flex-grow-1 p-3  rounded shadow-sm d-flex flex-column"
          style={{ maxHeight: "calc(100vh - 380px)" }}
        >
          <div className="table-container scroll flex-grow-1">
            {isTransferMode ? (
              <TableTransfer />
            ) : (
              <Table
                rollId={rollId}
                setAmount={setAmount}
                currency={selectedCurrency}
              />
            )}
          </div>

          <div className="pagination-container mt-2 me-3">
            <Pagination />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
