import React, { useEffect, useState } from "react";
import { GetRegisteredHGOs } from "../../../../Actions/AuthorizationActions";
import { CreateAccumulativeRequest } from "../../../../Actions/LedgerActions";
import { getCurrentDate, SuccessToast } from "../../../../Util";

const AccmulativeRequestContainer = () => {
  const [values, setValues] = useState({
    bankingFee: 0,
    totalAmount: null,
    narration: "",
  });

  const [type, setType] = useState("MonazamTransfer");
  const [currency, setCurrency] = useState("PKR");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [requests, setRequests] = useState([]);
  const [hgos, setHGOs] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const [rows, setRows] = useState([
    {
      id: "",
      num: 0,
      amount: null,
    },
  ]);

  const addNewRow = () => {
    setRows([
      ...rows,
      {
        id: "",
        num: rows.length,
        amount: null,
      },
    ]);
  };

  // const addNewRow = () => {
  //   SuccessToast("Maximum limit of rows reached!");
  // };

  const handleCreate = () => {
    SuccessToast("Accumulative request created successfully.");
    setValues({
      bankingFee: undefined,
      totalAmount: undefined,
      narration: "",
    });
    setRows([
      {
        id: "",
        num: 0,
        amount: null,
      },
    ]);
    setValidationErrors({});
  };

  const handleSubmit = () => {
    const errors = {};

    if (!type) errors.type = true;
    if (!currency) errors.currency = true;
    if (!values.narration?.trim()) errors.narration = true;
    if (values.bankingFee === null || values.bankingFee === undefined) {
      errors.bankingFee = true;
    }

    rows.forEach((row) => {
      if (!row.id || row.id === "Search") {
        errors[`hgo_${row.num}`] = true;
      }
      if (!row.amount || row.amount <= 0) {
        errors[`amount_${row.num}`] = true;
      }
    });

    setValidationErrors(errors);

    if (Object.keys(errors).length === 0) {
      CreateAccumulativeRequest(
        values,
        rows,
        type,
        new Date(),
        currency,
        setLoading,
        setError,
        handleCreate
      );
    }
  };

  function loadHGOs() {
    GetRegisteredHGOs(setError, setHGOs);
  }

  useEffect(() => {
    loadHGOs();
  }, []);

  return (
    <>
      <div className="bgWhite">Transfer</div>
      <div className="heading date">
        <h6>Accumulative Request</h6>
        <div className="d-flex flex-column me-4">
          <label>Date</label>
          <input type="date" value={getCurrentDate()} readOnly />
        </div>
      </div>
      <div className="container-fluid ">
        <div className="myContainer">
          <div className="form">
            <div className="row">
              <div className="column">
                <label className="text-sm">Type *</label>
                <select
                  style={{ width: "300px" }}
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  required
                >
                  <option value="MonazamTransfer" className="text-sm">
                    Monazam to Opap Transfer
                  </option>
                  <option value="OpapTransfer">Opap to eHajj Transfer</option>
                </select>
              </div>
              <div className="column d-flex">
                <label className="text-sm">Currency *</label>
                <select
                  style={{ width: "300px" }}
                  value={currency}
                  onChange={(e) => {
                    setCurrency(e.target.value);
                  }}
                  required
                >
                  {type === "MonazamTransfer" && (
                    <>
                      <option value="PKR">PKR</option>
                      <option value="USD">USD</option>
                    </>
                  )}
                  {type === "OpapTransfer" && <option value="SAR">SAR</option>}
                </select>
              </div>
            </div>
            {rows.map((row) => (
              <div className="row " key={row.id}>
                <div className="column">
                  <label className="text-sm">HGO Company *</label>
                  <select
                    style={{
                      width: "300px",
                      borderColor: validationErrors[`hgo_${row.num}`]
                        ? "red"
                        : "",
                    }}
                    value={row.id}
                    onChange={(e) => {
                      if (!!e.target.value) {
                        let updatedRows = rows.map((trow) => {
                          if (trow.num === row.num) {
                            trow.id = e.target.value;
                          }
                          return trow;
                        });
                        setRows(updatedRows);
                        setValidationErrors({
                          ...validationErrors,
                          [`hgo_${row.num}`]: false,
                        });
                      }
                    }}
                    required
                  >
                    <option value="">Search</option>
                    {hgos
                      .filter((hgo) => {
                        let reqFound = rows.filter(
                          (trow) => trow.id === hgo.id && row.id !== hgo.id
                        );
                        return !reqFound.length;
                      })
                      .map((hgo) => (
                        <option key={hgo?.id} value={hgo?.id}>
                          {hgo?.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="column">
                  <label className="text-sm">Amount *</label>
                  <input
                    style={{
                      width: "300px",
                      borderColor: validationErrors[`amount_${row.num}`]
                        ? "red"
                        : "",
                    }}
                    type="number"
                    placeholder="Enter amount"
                    value={row.amount}
                    onChange={(e) => {
                      if (!!e.target.value) {
                        let value = e.target.value;
                        value = value
                          ?.replace(/[^0-9.]/g, "")
                          ?.replace(/(\..*?)\..*/g, "$1")
                          ?.replace(/(\.\d{2})\d+/, "$1");
                        let total = 0.0;
                        let updatedRows = rows.map((trow) => {
                          if (trow.num === row.num) {
                            trow.amount = parseFloat(value);
                            total += parseFloat(value);
                          } else {
                            total += trow.amount || 0;
                          }
                          return trow;
                        });
                        setValues({ ...values, totalAmount: total });
                        setRows(updatedRows);
                        setValidationErrors({
                          ...validationErrors,
                          [`amount_${row.num}`]: false,
                        });
                      }
                    }}
                    required
                  />
                </div>
              </div>
            ))}
            <button className="add-btn" onClick={addNewRow}>
              Add +
            </button>
            <div className="rola mt-3">
              <div className="d-flex justify flex-column gap-3" style={{}}>
                <div className="d-flex flex-row align-items-center gap-3">
                  <label style={{ width: "100px" }}>Narration *</label>
                  <input
                    type="text"
                    value={values.narration}
                    placeholder="Narration"
                    onChange={(e) => {
                      setValues({
                        ...values,
                        narration: e.target.value,
                      });
                      setValidationErrors({
                        ...validationErrors,
                        narration: false,
                      });
                    }}
                    className="form-control"
                    style={{
                      width: "200px",
                      borderColor: validationErrors.narration ? "red" : "",
                    }}
                    required
                  />
                </div>
                <div className="d-flex flex-row align-items-center gap-3">
                  <label style={{ width: "100px" }}>Banking Fee *</label>
                  <input
                    type="number"
                    value={!!values.bankingFee ? values.bankingFee : 0}
                    onChange={(e) => {
                      setValues({
                        ...values,
                        bankingFee: parseInt(e.target.value),
                      });
                      setValidationErrors({
                        ...validationErrors,
                        bankingFee: false,
                      });
                    }}
                    placeholder="Banking Fee"
                    className="form-control"
                    style={{
                      width: "200px",
                      borderColor: validationErrors.bankingFee ? "red" : "",
                    }}
                    required
                  />
                </div>
                <div className="d-flex flex-row align-items-center gap-3">
                  <label style={{ width: "100px" }}>Total *</label>
                  <input
                    type="number"
                    value={!!values.totalAmount ? values.totalAmount : 0}
                    placeholder="Total"
                    onChange={(e) => {
                      setValues({
                        ...values,
                        totalAmount: parseInt(e.target.value),
                      });
                    }}
                    className="form-control"
                    style={{ width: "200px" }}
                    required
                  />
                </div>
              </div>
            </div>

            <button className="submit-btn mt-3" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccmulativeRequestContainer;
