// import React from 'react';
// import Tabs from '../../common_components/Tabs';
// import Filters from './Filterbtn';
// import Table from './Table';
// import Pagination from './Pagination';

// const MainContent = ({ rollId }) => {
//   return (
//     <div className="mt-2 main-content1">
//       <Tabs />

//       <div className="ps-lg-4 ps-md-4 pt-4 maincontent-container1 mt-2">
//         <Filters rollId={rollId} />

//         <div className="table-pagination-container shadow-sm p-3 m-lg-3 bg-white rounded">
//           <Table rollId={rollId}  />
//           <div className="d-flex justify-content-end mt-5">
//             <Pagination />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MainContent;

import React, { useState } from "react";
import Tabs from "../../common_components/Tabs";
import Filters from "./Filterbtn";
import Table from "./Table";
import Pagination from "./Pagination";
import { useRollId } from "../../common_components/RollIdContext";
import AccmulativeRequestContainer from "../../accumulative_request/AccmulativeRequestContainer";

const MainContent = ({ reload, accumulativeRequest }) => {
  const { rollId } = useRollId(); // Get rollId directly from context
  const [filters, setFilters] = useState({
    date: "",
    currency: "",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTP] = useState(0);
  return (
    <div className="container-fluid main-content-table bgColor d-flex flex-column overflow-hidden mt-2">
      {/* Main Content Wrapper */}
      <div className="flex-grow-1 d-flex flex-column pt-2">
        {accumulativeRequest ? (
          <AccmulativeRequestContainer />
        ) : (
          <>
            {/* <div className="table-pagination-container shadow-sm p-3 m-lg-3 bg-white rounded">
              <div className="d-flex justify-content-end mt-5"> */}
            {/* Filters */}

            <div className="mt-3">
              <Filters filters={filters} setFilters={setFilters} />
            </div>

            {/* Table and Pagination Container */}
            <div className="table-wrapper marginRight flex-grow-1 p-3 rounded shadow-sm d-flex flex-column">
              <div
                className="table-container scroll flex-grow-1"
                style={{ maxHeight: "calc(100vh - 420px)" }}
              >
                <Table
                  reload={reload}
                  filters={filters}
                  setTotalPages={setTP}
                  page={page}
                  setPage={setPage}
                />
              </div>

              {/* Pagination */}
              <div className="pagination-container mt-2 me-3">
                <Pagination
                  totalPages={totalPages}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default MainContent;
